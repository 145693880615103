import React from 'react';
import logo from './logo.svg';
import './App.css';

import { UserOutlined } from '@ant-design/icons';
import type { FormProps } from 'antd';
import { Button, Checkbox, Avatar, Form, Input } from 'antd';
import { useState } from 'react';
import { QRCodeCanvas } from 'qrcode.react';
import { db } from "./firebase";
import { collection, addDoc } from "firebase/firestore";
import { Spin } from 'antd';

type FieldType = {
  name?: string;
  email?: string;
  cpf?: string;
  cep?: string;
  endereco?: string;
  numero?: string;
};

const onFinishFailed: FormProps<FieldType>['onFinishFailed'] = (errorInfo) => {
  console.log('Failed:', errorInfo);
};

function App() {
  const onFinish: FormProps<FieldType>['onFinish'] = async (values) => {
    setLoad(true)
    const jwt = await reloadUser()
    await handleRequestQRCode(jwt)
    handleSubmit()
    setView(1)
    console.log('Success:', values);
  };

  const [view, setView] = useState(0);
  const [load, setLoad] = useState(false);
  const [qr, setQr] = useState("teste");
  const [email, setEmail] = useState("teste");
  const [label, setLabel] = useState("Copiar Código QR-code");


  const handleSubmit = async () => {
    try {
      await addDoc(collection(db, "users_drop"), {
        age: 1,
      });
    } catch (error) {
    }
  };

  const reloadUser = async () => {
    try {
      const url = 'https://production.api.allmapix.com.br/api/auth/issue';
      const headers = {
        'Content-Type': 'application/json',
      };

      const body = {
        "clientId": "143c4117bf67104da9167f71855cd3a2",
        "clientSecret": "21508c835318def4060f05af4be5e46859a575780f3ddd581df81e1ab22b225e"
      };

      try {
        const response = await fetch(url, {
          method: 'POST',
          headers,
          body: JSON.stringify(body),
        });
        const data = await response.json();


        return data.data
      } catch (error) {
        console.error('Error:', error);
      }
    } catch (err) {
      console.error("Falha ao copiar o texto: ", err);
    }
  };

  const copyText = async () => {
    try {
      await navigator.clipboard.writeText(qr);
      setLabel("Copiado!")
    } catch (err) {
      console.error("Falha ao copiar o texto: ", err);
    }
  };

  const handleRequestQRCode = async (jwt: any) => {
    const url = 'https://production.api.allmapix.com.br/api/charges';
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${jwt}`,
    };
    console.log(jwt)

    const body = {
      "calendar": {
        "expiration": 86400
      },
      "debtor": {
        "name": "João da Silva",
        "cpf": "42585048829"
      },
      "pricing": {
        "amount": "99.00",
        "canBeChangedByPayer": false
      },
      "observations": "Pagamento referente à fatura #1234"
    };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers,
        body: JSON.stringify(body),
      });

      const data = await response.json();
      setQr(data.data.copyAndPaste)
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className="background">
      <div className="centered-container">
        <Form>
          <div style={{ display: 'flex' }}>
            <Avatar
              shape="square"
              size={150}
              src="https://firebasestorage.googleapis.com/v0/b/my-farm-land-556ff.appspot.com/o/cn-11134207-7r98o-lwfq2plkkyf9a2%20(1)%20(1).jpg?alt=media&token=d913ecf5-b28c-4b1c-ab12-43f21dac974e"
            />
            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
              <h3 style={{ padding: '0px', margin: '0px', marginTop: '8px' }}>Lego Porsche 911 RSR</h3>
              <p style={{ padding: '0px', margin: '0px' }}>1600 peças</p>
              <p style={{ padding: '0px', margin: '0px', textAlign: 'start' }}>
                ** Caixa ???? + manual + frete grátis <br />
                via PAC
              </p>
              <p style={{ padding: '0px', margin: '0px', marginTop: '10px', display: 'flex', alignItems: 'center', fontSize: '22px' }}>
                <h2 style={{ padding: '0px', margin: '0px', marginRight: '100px', fontSize: 20 }}>R$:</h2> 99,00
              </p>
            </div>
          </div>
        </Form>

        {view == 0 ? <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <p style={{ fontSize: '12px', padding: '0px', marginTop: '10px', marginBottom: '10px', color: 'red' }}>
            Preço e condições promocionais enquanto durarem os estoques! <br />(para produtos já em solo brasileiro)!
          </p>

          <Form.Item<FieldType>
            label=""
            name="name"
           rules={[{ required: true, message: 'Por favor, insira seu nome!' }]}
          >
            <Input placeholder="Nome" />
          </Form.Item>

          <Form.Item<FieldType>
            label=""
            name="email"
            rules={[{ required: true, message: 'Por favor, insira seu e-mail!' }]}
          >
            <Input type="email" placeholder="E-mail" />
          </Form.Item>

          <Form.Item<FieldType>
            label=""
            name="cpf"
            rules={[{ required: true, message: 'Por favor, insira seu CPF!' }]}
          >
            <Input placeholder="CPF" />
          </Form.Item>

          <Form.Item<FieldType>
            label=""
            name="cep"
            rules={[{ required: true, message: 'Por favor, insira seu CEP!' }]}
          >
            <Input placeholder="CEP" />
          </Form.Item>

          <Form.Item<FieldType>
            label=""
            name="endereco"
            rules={[{ required: true, message: 'Por favor, insira seu Endereço!' }]}
          >
            <Input placeholder="Endereço" />
          </Form.Item>

          <Form.Item<FieldType>
            label=""
            name="numero"
            rules={[{ required: true, message: 'Por favor, insira o Número do seu endereço!' }]}
          >
            <Input placeholder="Número" />
          </Form.Item>

          <p style={{ fontSize: '12px', color: '#BBC4D0' }}>
            Nós protegemos seus dados de pagamento usando criptografia para fornecer segurança no nível de bancos.
          </p>

          <Form.Item>
            { !load ? <Button size="large" style={{ minWidth: '330px', backgroundColor: '#46C900' }} type="primary" htmlType="submit">
              Realizar pafamento via PIX
            </Button> : <Spin style={{ backgroundColor: '#46C900' }}></Spin>}
          </Form.Item>

          <img
            style={{ width: '75px' }}
            src="https://firebasestorage.googleapis.com/v0/b/my-farm-land-556ff.appspot.com/o/footer-kiwify-gray.webp?alt=media&token=b0c4b313-63e6-4b92-8799-de28b0815723"
          />

          <p style={{ fontSize: '8px', color: '#BBC4D0' }}>
            Ao clicar em 'Pagar Agora', declaro que (i) estou ciente de que a Kiwify está processando essa compra em nome de HEY INVESTIDOR MARKETING LTDA e que não possui responsabilidade pelo conteúdo, oferta, e nem faz controle prévio do infoproduto; (ii) que li e concordo com os Termos de Compra, Termos de Uso, e Política de Privacidade.
          </p>
          <p style={{ fontSize: '10px', padding: '0px', margin: '0px', color: '#BBC4D0' }}>Denunciar este produto.</p>
          <p style={{ fontSize: '10px', padding: '0px', margin: '0px', color: '#BBC4D0' }}>*Parcelamento com acréscimo.</p>
          <p style={{ fontSize: '11px', padding: '0px', margin: '0px', color: '#BBC4D0' }}>Este site está protegido pelo Google reCAPTCHA.</p>
          <p style={{ fontSize: '9px', padding: '0px', margin: '0px', color: '#BBC4D0' }}>Política de Privacidade e Termos de Serviço.</p>
        </Form> :
          <div>
            <Form
              name="basic"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <br></br>
              <p style={{ fontSize: '11px', padding: '0px', margin: '0px', color: '#BBC4D0' }}>Após realizar o pagamento você receberá  no E-mail informado a confirmação do pagamento e acompanhamento do pedido!</p>

              <div style={{ marginTop: 20, marginBottom: 20 }}>
                <QRCodeCanvas value={qr} size={200} />
              </div>


              <Form.Item>
                <Button size="large" onClick={() => copyText()} style={{ minWidth: '220px', backgroundColor: '#46C900' }} type="primary" htmlType="submit">
                  {label}
                </Button>
              </Form.Item>



            </Form>

            <img
              style={{ width: '75px' }}
              src="https://firebasestorage.googleapis.com/v0/b/my-farm-land-556ff.appspot.com/o/footer-kiwify-gray.webp?alt=media&token=b0c4b313-63e6-4b92-8799-de28b0815723"
            />

            <p style={{ fontSize: '8px', color: '#BBC4D0' }}>
              Ao clicar em 'Pagar Agora', declaro que (i) estou ciente de que a Kiwify está processando essa compra em nome de HEY INVESTIDOR MARKETING LTDA e que não possui responsabilidade pelo conteúdo, oferta, e nem faz controle prévio do infoproduto; (ii) que li e concordo com os Termos de Compra, Termos de Uso, e Política de Privacidade.
            </p>
            <p style={{ fontSize: '10px', padding: '0px', margin: '0px', color: '#BBC4D0' }}>Denunciar este produto.</p>
            <p style={{ fontSize: '10px', padding: '0px', margin: '0px', color: '#BBC4D0' }}>*Parcelamento com acréscimo.</p>
            <p style={{ fontSize: '11px', padding: '0px', margin: '0px', color: '#BBC4D0' }}>Este site está protegido pelo Google reCAPTCHA.</p>
            <p style={{ fontSize: '9px', padding: '0px', margin: '0px', color: '#BBC4D0' }}>Política de Privacidade e Termos de Serviço.</p>
          </div>}
          <iframe data-aa='2380484' src='//ad.a-ads.com/2380484?size=728x90' style={{ width: "728px", height: "90px", border: "0px", padding: "0", overflow: "hidden", backgroundColor: "transparent" }}></iframe>
      </div>
      
    </div>
  );
}

export default App;
